import React from "react";
import { privacyPolicy } from "../../utils/mock";
import {Icon} from "../../ui";

const PrivacyPolicy = () => {

  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>Privacy Policy</h4>
                <p>
                  Welcome to Scorefusion! Your privacy is important to us. This
                  Privacy Policy explains how we collect, use, and protect your
                  personal information when you use our website and services.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="commanOther">
              <h4>Scorefusion.com is the brand of Privacy Policy</h4>
              <p>
                We are the flagship brand of <Icon name="bepvt" className="img-fluid"/>, offering Our
                Services to You wherein You can also participate in various
                Contests hosted on Our Platform. Any person utilizing the
                Platform or any of its features, including participation in
                various Contests being conducted on the Platform, shall be bound
                by the terms of this privacy policy (“Privacy Policy”).
              </p>
              <p>
                All capitalized terms not defined herein shall have the meaning
                ascribed to them in the Terms.
              </p>
              <p>
                We respect the privacy of its User(s) and are committed to
                protecting it in all respects. With a view to offer an enriching
                and holistic internet experience to its Users, We offer a vast
                repository of Services on Our Platform. Kindly take time to read
                the “About Us” section to know more. Most of the Services are
                offered for free but you need registration to participate in the
                Contests. The information about the User(s) is collected by Us
                as (i) information supplied by User(s) and (ii) information
                automatically tracked during User(s)’ navigation on the
                Platform.
              </p>
              <p>
                Before you submit any information on the Platform, please read
                this Privacy Policy for an explanation of how We will treat Your
                information. By using any part of the Platform, You consent to
                the collection, use, disclosure and transfer of Your information
                for the purposes outlined in this Privacy Policy and to the
                collection, processing and maintenance of this information. Your
                use of any part of the Platform indicates Your acceptance of
                this Privacy Policy and of the collection, use and disclosure of
                Your information in accordance with this Privacy Policy.
              </p>
              <p>
                While You have the option not to provide Us with certain
                personal information, withdraw Your consent to collect certain
                information, request temporary suspension of collection of
                personal information or request deletion of personal information
                collected, kindly note that in such an event You may not be able
                to take full advantage of the entire scope of features and
                services offered to You and We reserve the right not to provide
                You with Our services.
              </p>
              <p>
                In the ever-evolving medium of the internet, We reserve our
                right to periodically review and change Our Privacy Policy to
                incorporate such future changes as may be considered appropriate
                and under applicable laws. We will notify You of the change. Any
                changes to Our Privacy Policy will be posted on this page, so
                You are always aware of what information We collect, how We use
                it, how We store it and under what circumstances We disclose
                it.,
              </p>
            </div>
            {privacyPolicy.map((section, index) => (
              <div className="commanOther" key={index}>
                <h4>{section.title}</h4>
                <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>

              </div>
            ))}
            <div className="commanOther">
              <Icon name="bepvt" className="img-fluid"/>
              <p>
                Address :{" "}
                <spn>
                  Mig Ii 5/13 3rd Lane Forest Colony, Bda Colony Chandrasekharpur, Bhubaneswar, Khordha, Orissa, India, 751016
                </spn>
              </p>
            </div>
            
         {/*  <div className="commanOther">
            <h4>8. Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:{" "}
              <a href="mailto:support@frolicfuns.com">support@frolicfuns.com</a>
            </p>
          </div> */}
          </div>
          {/* <p>
            <b>Thank you for using FrolicFuns!</b>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;