import React from "react";
import { termsSections } from "../../utils/mock";
import {Icon} from "../../ui";

const TermsCondition = () => {

  return (
    <div className="container">
      <div className="policy_text">
        <div className="row justify-content-center my-2">
          <div className="col-lg-8 col-md-10 text-center">
            <div className="commantext">
              <h2 className="mb-4">Terms and Conditions</h2>
              <p className="lead mb-4">
                Welcome to Scorefusion! By accessing or using our website and
                services, you agree to comply with and be bound by the
                following terms and conditions. Please read them carefully.
              </p>
            </div>
          </div>
        </div>
        <div className="commanOther">
          <h4>1. Introduction - Who We Are and What We Do</h4>
          <p>
            We are the brand of{" "}
            <Icon name="bepvt" className="img-fluid"/>, offering Our Platform to
            You and an opportunity for You to participate in Contests spanning
            across a broad range of officially sanctioned live sporting events
            (“Services”). An illustrative list of such sporting events is
            mentioned below as maybe modified from time to time (“Sports”):
          </p>

          <p>i. Cricket</p>
          <p>ii. Football</p>
          <p>iii. Basketball</p>
          <p>iv. Baseball</p>
          <p>v. Hockey</p>
          <p>vi. Handball</p>
          <p>vii. Volleyball</p>
          <p>viii. Kabaddi</p>
          <p>
            Any person using, accessing and/or participating in any fantasy
            sports-related free-to-play online gaming contests (“Practice
            Contest”) and/or pay-to-play online gaming contests (“Paid Contest”)
            on Our Platform is a user (“User”). All references to “You/Your”
            relate to the User. All references to “We/Us/Our” relate to
            “Scorefusion.online” which denotes a collective reference to the
            Scorefusion.online mobile application and the Scorefusion.online website
            (hereinafter collectively referred to as “Platform”). Practice
            Contest and Paid Contest are hereinafter collectively referred to as
            “Contests”.
          </p>
        </div>
        {termsSections.map((section, index) => (
          <div className="commanOther pre" key={index}>
            <h3 className="mb-3">{section.title}</h3>
            <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>
          </div>
        ))}

      {/*   <div className="commanOther">
          <h3 className="mb-3">14. Contact Us</h3>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at:{" "}
            <a href="mailto:support@frolicfuns.com">support@frolicfuns.com</a>
          </p>
        </div>

        <p className="mt-4">
          <b>Thank you for using FrolicFuns!</b>
        </p> */}
        <h4>19. App developer  </h4>
        <p>
          The website https://scorefusion.online/ and the app is developed and maintained by <Icon name="bepvt" className="img-fluid"/>.
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
