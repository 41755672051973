import React  from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3" />
                  </p>
                  <p>
                    Owned and operated by  <Icon name="bbepvt" className="cmpny"/>.,
                    Scorefusion.com is an online fantasy game designed for the
                    fans who like to showcase their cricket knowledge and
                    analytical skills.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p>
                    <p
                      onClick={() => {
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </p>
                  </p>
                  <p>
                    <p
                      onClick={() => {
                        handleLinkClick("usp");
                      }}
                    >
                      Hot to Play
                    </p>
                  </p>
                  <p>
                    <p
                      onClick={() => {
                        handleLinkClick("whychoose");
                      }}
                    >
                      Why Choose Us?
                    </p>
                  </p>
                  <p>
                    <NavLink to={"/about-us"}>About us</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Services</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/refund-return"}>Refund & Cancellation</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/game-rules"}>Game Rules</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                  <p>
                    <a href="mailto:admin@scorefusion.online">
                      admin@scorefusion.online
                    </a>
                  </p>
                  <div className="d-flex align-items-center gap-2 followus ">
                    <p>
                      <FaFacebook fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <FaTwitter fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <FaLinkedin fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <IoMdMailOpen fill="var(--primary-200)" size={24} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Scorefusion</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
